<template>
    <div>
        <div class="d-flex flex-wrap">
            <!-- Kart 1: Kullanıcı Bilgileri -->
            <b-col md="6" class="p-3">
                <b-card :title="$t('student_information')" class="h-100">
                        <b-col class="mb-3">
                            <b-form-group :label="$t('name')">
                                <b-input-group>
                                    <div class="label-as-input">{{ form.name || '-' }}</div>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col class="mb-3">
                            <b-form-group :label="$t('email')">
                                <b-input-group>
                                    <div class="label-as-input">{{ form.email || '-' }}</div>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col class="mb-3">
                            <b-form-group :label="$t('phone_number')">
                                <b-input-group>
                                    <div class="label-as-input">{{ form.mobile_tel || '-' }}</div>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col class="mb-3">
                            <b-form-group :label="$t('country_code')">
                                <b-input-group>
                                    <div class="label-as-input">{{ form.country_code || '-' }}</div>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                </b-card>
            </b-col>

            <!-- Kart 2: Konaklama Bilgileri -->
            <b-col md="6" class="p-3">
                <b-card :title="$t('reservation_information')">
                        <b-col class="mb-3">
                            <b-form-group :label="$t('building_name')">
                                <b-input-group>
                                    <div class="label-as-input">{{ form.building_name || '-' }}</div>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col class="mb-3">
                            <b-form-group :label="$t('room_name')">
                                <b-input-group>
                                    <div class="label-as-input">{{ form.room_name || '-' }}</div>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col class="mb-3">
                            <b-form-group :label="$t('room_type_name')">
                                <b-input-group>
                                    <div class="label-as-input">{{ form.room_type_name || '-' }}</div>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col class="mb-3">
                            <b-form-group :label="$t('housing_beds')">
                                <b-input-group>
                                    <div class="label-as-input">{{ form.bed_name || '-' }}</div>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col class="mb-3">
                            <b-form-group :label="$t('bed_type')">
                                <b-input-group>
                                    <div class="label-as-input">{{ form.bed_type_name || '-' }}</div>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                </b-card>
            </b-col>
            <b-col md="6" class="p-3 mt-3">
                <b-card :title="form.request_type_name">
                    <div v-if="form.request_data && Object.keys(form.request_data).length">
                        <b-col class="mb-3" v-for="(item, key) in form.request_data" :key="key">
                            <b-form-group :label="$t(key)">
                                <b-input-group>
                                    <template v-if="item && typeof item === 'object' && Object.keys(item).length">
                                        <div class="label-as-input" v-for="(val, subKey) in item" :key="subKey">{{ val }}</div>
                                    </template>
                                    <template v-else>
                                        <div class="label-as-input">{{ item }}</div>
                                    </template>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </div>
                </b-card>
            </b-col>
            <b-col md="6" class="p-3 mt-3 ">
                <b-card title="files" class="h-100">
                    <b-col class="mb-3">
                        <b-form-group :label="$t('work_order_files')">
                            <template v-if="form.work_order_completed_files.length">
                                <div v-for="(file) in form.work_order_completed_files" :key="file.uuid">
                                    <b-input-group class="mb-2">
                                        <div class="label-as-input">{{ file.file_name }}</div>
                                        <b-input-group-append>
                                            <b-button variant="primary" @click="downloadFile(file.uuid,file.file_name)"
                                                      v-b-tooltip.hover
                                                      title="Download Document" :disabled="!file.uuid">
                                                <i class="ri-arrow-down-line"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </template>
                            <template v-else>
                                <b-input-group class="mb-2">
                                    <div class="label-as-input">{{ 'File Not Fount' }}</div>
                                </b-input-group>
                            </template>
                        </b-form-group>
                    </b-col>
                    <b-col class="mb-3">
                        <b-form-group :label="$t('agreement_files')">
                            <template v-if="form.agreement_files.length">
                                <div v-for="(file) in form.agreement_files" :key="file.uuid">
                                    <b-input-group class="mb-2">
                                        <div class="label-as-input">{{ file.file_name }}</div>
                                        <b-input-group-append>
                                            <b-button variant="primary" @click="downloadFile(file.uuid,file.file_name)"
                                                      v-b-tooltip.hover
                                                      title="Download Document" :disabled="!file.uuid">
                                                <i class="ri-arrow-down-line"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </template>
                            <template v-else>
                                <b-input-group class="mb-2">
                                    <div class="label-as-input">{{ 'File Not Fount' }}</div>
                                </b-input-group>
                            </template>

                        </b-form-group>
                    </b-col>
                    <b-col class="mb-3">
                        <b-form-group :label="$t('file')">
                            <template v-if="form.files.length">
                                <div v-for="(file) in form.files" :key="file.uuid">
                                    <b-input-group class="mb-2" >
                                        <div class="label-as-input">{{ file.file_name }}</div>
                                        <b-input-group-append>
                                            <b-button variant="primary" @click="downloadFile(file.uuid,file.file_name)"
                                                      v-b-tooltip.hover
                                                      title="Download Document" :disabled="!file.uuid">
                                                <i class="ri-arrow-down-line"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </template>
                            <template v-else>
                                <b-input-group class="mb-2">
                                    <div class="label-as-input">{{ 'File Not Fount' }}</div>
                                </b-input-group>
                            </template>
                        </b-form-group>
                    </b-col>
                </b-card>
            </b-col>

            <!-- Kart 3: Talep Bilgileri -->
            <b-col cols="12" class="p-3 mt-3">
                    <b-card :title="$t('request_information')" class="h-100">
                        <b-row>
                        <b-col class="mb-3">
                            <label>{{ $t('request_type') }}</label>
                            <b-input-group>
                                <div class="label-as-input">{{ form.request_type_name || '-' }}</div>
                            </b-input-group>
                        </b-col>
                        <b-col class="mb-3">
                            <label>{{ $t('explanation') }}</label>
                            <b-input-group>
                                <div class="label-as-input">{{ form.explanation || '-' }}</div>
                            </b-input-group>
                        </b-col>
                        <b-col class="mb-3">
                            <ValidationObserver ref="formModalValidate">
                                <ValidationProvider name="status" rules="required" v-slot="{ errors }">
                                    <label><b>{{ $t('status') }}</b></label>
                                    <parameter-selectbox
                                        v-model="form.status"
                                        :validate-error="errors[0]"
                                        code="housing_request_statuses"
                                    />
                                </ValidationProvider>
                            </ValidationObserver>
                        </b-col>
                        </b-row>
                    </b-card>
            </b-col>

        </div>
        <div class="d-flex justify-content-center mt-2" v-if="checkPermission('housingrequest_changestatus')">
            <b-button variant="primary" @click="updateStatus">
                {{ $t('save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import HousingRequestListServices from "@/services/HousingRequestListServices";
export default {
    props: {
        formId: {}
    },
    data() {
        return {
            form: {
                name: null,
                email: null,
                mobile_tel: null,
                country_code: null,
                building_name: null,
                room_name: null,
                room_type_name: null,
                bed_name: null,
                bed_type_name: null,
                request_type_name: null,
                explanation: null,
                status: null,
                agreement_files: [],
                files: [],
                request_data: [],
                work_order_completed_files: []
            }
        };
    },
    computed:{
      requestData(){
          return this.form.request_data
      } ,
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            HousingRequestListServices.showAdmin(this.formId)
                .then((res) => {
                    const data = res.data.data;
                    let request_data = data.request_data;
                    delete request_data['housing_bed_id'];
                    if(data.request_type=='airport_transfer_request'){
                        delete request_data['transfer_places'];
                        delete request_data['airport'];
                        delete request_data['name'];
                        delete request_data['mobile_tel'];
                        delete request_data['email'];
                    }
                    this.form = {
                        request_data: request_data,
                        name: `${data.name} ${data.surname}`,
                        email: data.email || '-',
                        mobile_tel: data.mobile_tel || '-',
                        country_code: data.mobile_tel_country_code || '-',
                        building_name: data.building_name || '-',
                        room_name: data.room_name || '-',
                        room_type_name: data.room_type_name || '-',
                        bed_name: data.bed_name || '-',
                        bed_type_name: data.bed_type_name || '-',
                        request_type_name: data.request_type_name || '-',
                        explanation: data.explanation || '-',
                        files: data.files || [],
                        status: data.status || '-',
                        flight_code: data.request_data?.flight_code || '-',
                        agreement_files: data.agreement_files ?? [],
                        work_order_completed_files: data.work_order_completed_files ?? [],
                    };
                })
                .catch((error) => {
                    this.showErrors(error);
                });
        },
        async updateStatus() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                try {
                    const res = await HousingRequestListServices.changeStatusRequest(this.formId, this.form);
                    this.$toast.success(this.$t("api." + res.data.message));
                    this.$emit("successStatusUpdate", true);
                } catch (error) {
                    if (error.response.status === 422) {
                        this.$toast.success(this.$t("invalid_data"));
                    }else {
                        this.showErrors(error);
                    }
                }
            }
        },
        downloadFile(uuid,filename) {
            HousingRequestListServices.downloadFile(uuid)
                .then((response) => {
                    this._downloadFile(response, filename)
                })
                .catch((err) => {
                    this.showErrors(err);
                });
        }
    }
};
</script>
