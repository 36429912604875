<template >
    <div>
            <app-layout>
                <template v-slot:header>
                    <Header :title="$t('housing_request')"
                            @filter-div-status="datatable.filterStatus = $event">
                        <template v-slot:columns>
                            <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                                <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                                 :unchecked-value="true" v-model="column.hidden"
                                                 v-if="column.field != 'buttons'">
                                    {{ column.label }}
                                </b-form-checkbox>
                            </div>
                        </template>
                    </Header>
                </template>
                <template v-slot:header-mobile>
                    <HeaderMobile :title="$t('housing_request_list')"
                                  @filter-div-status="datatable.filterStatus = $event">
                        <template v-slot:columns>
                            <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                                <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                                 :unchecked-value="true" v-model="column.hidden"
                                                 v-if="column.field != 'buttons'">
                                    {{ column.label }}
                                </b-form-checkbox>
                            </div>
                        </template>
                    </HeaderMobile>
                </template>
                <datatable-filter
                    v-show="datatable.filterStatus"
                    @filter="filter"
                    @filterClear="filterClear"
                >
                    <b-row>
                        <b-col sm="6">
                            <b-form-group :label="$t('request_type')">
                                <ParameterSelectbox code="housing_request_types" v-model="datatable.queryParams.filter.request_type"  />
                            </b-form-group>
                        </b-col>

                        <b-col sm="6">
                            <b-form-group :label="$t('status')">
                        <parameter-selectbox
                            v-model="datatable.queryParams.filter.status"
                            code="housing_request_statuses"
                        />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </datatable-filter>
                <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                           :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                           @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                           v-show="datatable.showTable">
                </datatable>

            </app-layout>

        <CommonModal ref="requestListFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalTitle>
                {{ $t('request_form').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <ShowRequestList v-if="formProcess === 'request-list-form'" :formId="formId" @successStatusUpdate="successStatusUpdate"/>
            </template>
        </CommonModal>
        <CommonModal ref="requestExpenseDetailForm" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalTitle>
                {{ $t('add_expense_detail').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <ExpenseDetailForm :formId="formId" v-if="formProcess === 'request-expense-detail-form'" @successExpenseCreate="successExpenseCreate"/>
            </template>
        </CommonModal>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout.vue"
import Header from "@/layouts/AppLayout/Header.vue"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue"
// Components
import Datatable from "@/components/datatable/Datatable.vue";
import HousingRequestListServices from "@/services/HousingRequestListServices";
import ShowRequestList from "@/modules/housingRequestList/pages/admin/ShowRequestList.vue";
import qs from "qs";
import ExpenseDetailForm from "@/modules/housingRequestList/pages/admin/ExpenseDetailForm.vue";
export default {
    components: {
        ExpenseDetailForm,
        ShowRequestList,
        AppLayout,
        Header,
        HeaderMobile,
        Datatable,

    },
    metaInfo() {
        return {
            title: this.$t('housing_request_list')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show_request_form'),
                                class: 'ri-edit-box-line',
                                permission: 'housingrequest_show',
                                callback: (row) => {
                                    this.requestFormListShow(row)
                                },

                            },
                            {
                                text: this.$t('add_expense_detail'),
                                class: 'ri-edit-box-line',
                                permission: 'housingrequest_show',
                                show:(col)=>{
                                    return col.request_type==='work_order_request'
                                },
                                callback: (row) => {
                                    this.requestExpenseDetailShow(row)
                                },

                            },
                        ]
                    },
                    {
                        label: this.toUpperCase('name')+this.toUpperCase('surname'),
                        field: 'name',
                        hidden: false,
                        formatFn:(col,row)=>{
                             return row.name + ' ' + row.surname
                        }
                    },     {
                        label: this.toUpperCase('email'),
                        field: 'email',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('gsm_no'),
                        field: 'mobile_tel',
                        hidden: false,
                        formatFn:(col,row)=>{
                            return row.mobile_tel_country_code? row.mobile_tel_country_code + ' ' + row.mobile_tel:'-'
                        }
                    },
                    {
                        label: this.toUpperCase('bed'),
                        field: 'bed_name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('bed_type'),
                        field: 'bed_type_name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('building_name'),
                        field: 'building_name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('floor'),
                        field: 'floor',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('room_name'),
                        field: 'room_name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('room_type_name'),
                        field: 'room_type_name',
                        hidden: false,
                    },


                    {
                        label: this.toUpperCase('request_type'),
                        field: 'request_type_name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('explanation'),
                        field: 'explanation',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('status'),
                        field: 'status',
                        hidden: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formId: null,
            form: {},
        }
    },
    methods: {
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },

        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            return HousingRequestListServices.getAllAdmin(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        requestFormListShow(row) {
            this.formId = row.id
            this.formData=row
            this.formProcess = 'request-list-form'
            this.$refs.requestListFormModal.$refs.commonModal.show()
        },
        requestExpenseDetailShow(row) {
            this.formId = row.id
            this.formProcess = 'request-expense-detail-form'
            this.$refs.requestExpenseDetailForm.$refs.commonModal.show()
        },
        successExpenseCreate(){
            this.formId = null
            this.formData=null
            this.formProcess = null
            this.$refs.requestExpenseDetailForm.$refs.commonModal.hide()
            this.getRows()
        },

            successStatusUpdate(){
            this.formId = null
            this.formData=null
            this.formProcess = null
            this.$refs.requestListFormModal.$refs.commonModal.hide()
            this.getRows()
        }

    }
}
</script>

