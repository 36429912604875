<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="expenseAmount" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('expense_amount')">
                            <b-form-input type="number" v-model="formData.expenseAmount">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="expenseDetail" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('expense_detail')">
                            <b-form-textarea
                                id="textarea"
                                v-model="formData.expenseDetail"
                                placeholder="Enter Detail"
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>

                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <ValidationProvider name="files" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('upload_your_documents')">
                            <div class="d-flex custom-file-upload">
                                <b-form-file
                                    v-model="formData.files"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :placeholder="$t('select_file')"
                                    :drop-placeholder="$t('drop_file')"
                                    ref="fileInput"
                                    multiple></b-form-file>
                                <b-button variant="outline-secondary"
                                          @click="$refs.fileInput.$el.childNodes[0].click();">
                                    {{ $t('browse') }}
                                </b-button>
                            </div>
                            <div class="invalid-feedback d-block" v-if="errors[0]"
                                 v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="save">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>

    </div>
</template>
<script>


import HousingRequestListServices from "@/services/HousingRequestListServices";

export default {
    props: {
        formId: Number,
    },
    components: {

    },
    data() {
        return {
            formData: {
                expenseAmount:null,
                files:null,
                expenseDetail:null,
            }
        }
    },

    methods: {
        async save() {
            const isValid =await this.$refs.formModalValidate.validate()
            if (!isValid) return
            const formData = new FormData();
            formData.append('expense_amount', this.formData.expenseAmount);
            formData.append('expense_detail', this.formData.expenseDetail);
            if (this.formData.files && this.formData.files.length > 0) {
                for (let i = 0; i < this.formData.files.length; i++) {
                    formData.append(`file[${i}]`, this.formData.files[i]);
                }
            }
            HousingRequestListServices.addExpenseDetail(this.formId,formData)
                .then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$emit('successExpenseCreate',true)
                })
                .catch(e => {
                    this.showErrors(e, this.$refs.formModalValidate);
                })



        },
    }
}
</script>
